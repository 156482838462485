

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

*{
  margin: 0 ;
  padding: 0 ;
  font-family: 'Poppins';

}
.loader{
  height: 100vh;
  background-color: black;
}

.color_apply{
  color:#00c08a; ;
}