.contact-sidebar-wrapper{
    position: fixed;
    top: 35vh;
    right: 0px;
    background-color: white;
    width: 150px;
    height: 200px;
    border-radius: 10px 0px 0px 10px ;
    transition: all 0.2s ease-in-out;
    box-shadow: 1px 1px 10px 1px rgba(211, 211, 211, 0.526);
    transform: translateX(20px);
    /* border: 2px solid red; */
    z-index: 10;
}
.call-us-box{
    position: relative;
    background-color: white;
    width: 100%;
    height:100%;
    border-radius: 10px 0px 0px 10px ;
    display: flex;
}
.hide-btn{
    transform: rotate(270deg);
    background-color: #2eb086;
    width: 200px;
    height: 30px;
    border: none;
    color: white;
    padding: 4px;
    position: absolute;
    border-radius: 10px 10px 0px 0px ;
    top: 85px;
    right: 0px;
    bottom: 0px;
    left: -85px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
}
.translate-sidebar{
    transform: translatex(120px);
}

@media only screen and (max-width:768px){
    .contact-sidebar-wrapper{
        display: none;
    }
}