.navbar-planet {
  z-index: 999 !important;

  transition: background-color 0.5s ease;
  /* box-shadow: 0px 0px 15px rgb(0 0 0 / 15%); */
  padding: 0px !important;
  position: fixed !important ;
  width: 100%;
  /* top: 0px; */

  /* height: 90px; */
  /* border: 1px solid red; */
  /* background: rgb(36, 36, 36); */
}
.navbar_bg {
  /* background-color: rgb(36, 36, 36); */
  background-color: white;

  backdrop-filter: blur(5px);
}
.font-color{
  color: white !important;
}
.navbar-container {
  z-index: 999 !important;
}
.logo-box {
  font-weight: 600;
  width: 100px;
  font-size: 20px;
  color: rgb(36, 36, 36);;
}

.home {
  background-color: #00000042;
  height: 1000vh;
}
.logo-box img {
  width: 40px;
  filter: brightness(200%);
}
.navitems {
  /* border: 2px solid red; */
  /* gap: 20px; */
  width: 30%;
  display: flex;
  justify-content: space-around;
  /* align-items: flex-end; */
  /* display: inline-block; */
}
.navitems .navbar-link {
  text-decoration: none;
  color: rgb(36, 36, 36);
  font-size: 15px;
  padding: 20px 0px;
  /* border:1px solid red; */
  font-weight: 600;
  line-height: 27px;
  transition: all 0.2s ease-in-out;
  letter-spacing: 0px;
  opacity: 0.9;
  position: relative;
}

.navitems .navbar-link.active {
  color: #05bd8e !important;
}

.navitems .navbar-link:before,
.navitems .navbar-link.active::before {
  content: "";
  display: block;
  /* border: 0.1px solid #05bd8e;
    margin-top: 10px; */
  background-color: #05bd8e;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  transform: scaleX(0);
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  border-radius: 0px 0px 10px 10px;
  height: 8px;
}
.navitems .navbar-link:hover:before,
.navitems .navbar-link.active::before {
  opacity: 1;
  transform: scaleX(1);
}

.navitems .navbar-link:hover {
  color: #05bd8e !important;
}
.navbar-hamburger {
  background-color: #05bd8e;
  font-size: 40px;
  padding: 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: none;
  /* height: 50px; */
}
.wedBellsHeader{
  font-size: 25px;
}

/* ##################################### Mobile navabar start here ########################################################333333 */
.mobile-navbar {
  /* background: rgb(36, 36, 36); */
  background: white;
  
  /* color: rgb(0, 0, 0); */
  color: black;
  width: 100%;
  position: fixed !important;
  top: 0;
  height: 350px;
  z-index: 10 !important;
  transform: translateY(-510px);
  transition: all 0.3s ease-in-out;
  opacity: 0;
  padding-top: 110px;
}
.mobile-navbar-down {
  transform: translateY(0px);
  opacity: 1;
}
.mobile-navbar-links {
  /* background-color: #05bd8e; */
  text-align: center;
  letter-spacing: 0px;
  font-weight: 500;
  color: #05bd8e !important;
  /* color: white !important; */
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  width: 300px;
  /* width: 100%; */
  margin: 12px auto;
}

.mobile-navbar-cross-icon {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.664);
}

@media only screen and (max-width: 1200px) {
  .logo-box {
    width: 90px;
  }
  .logo-box img {
    width: 40px;
  }
  .navitems {
    width: 32%;
  }
}
@media only screen and (max-width: 990px) {
  .navbar-container {
    height: 75px;
  }
  .navitems {
    display: none;
  }
  .navbar-hamburger {
    display: block;
    padding: 7px;
  }
}

@media only screen and (max-width: 600px) {
  .logo-box {
    width: 90px;
  }
  .navbar-container {
    padding: 5px 20px 5px 20px !important;
  }
  .navbar-hamburger {
    font-size: 35px;
    /* padding: 7px; */
  }
}
@media only screen and (max-width: 320px) {
  .logo-box {
    width: 90px;
  }
  .navbar-hamburger {
    font-size: 30px;
    /* padding: 7px; */
  }
}
